import React from "react";
import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const scaleIn = keyframes`
  from {
    transform: scale(0.95);
  }
  to {
    transform: scale(1);
  }
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 16px;
  animation: ${fadeIn} 0.3s ease;
`;

const Container = styled.div`
  position: relative;
  max-width: 1024px;
  width: 100%;
  animation: ${scaleIn} 0.3s ease;
`;

const CloseButton = styled.button`
  position: absolute;
  top: -16px;
  right: -16px;
  background-color: white;
  border: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f3f4f6;
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;

const PreviewImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
`;

const ImagePreview = ({ imageUrl, isOpen, onClose }) => {
  if (!isOpen) return null;

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <Backdrop onClick={handleBackdropClick}>
      <Container>
        <CloseButton onClick={onClose} aria-label="Close preview">
          X
        </CloseButton>
        <PreviewImage src={imageUrl} alt="Preview" />
      </Container>
    </Backdrop>
  );
};

export default ImagePreview;
