import styled from "styled-components";

export const Styles = styled.footer`
  padding: 5px 20px;
  // position: fixed;
  bottom: 0;
  width: 100%;

  background-color: rgba(256, 256, 256, 1);
  border-top: 1px solid ${({ colors }) => colors.color.lightGrey} !important;

  .facebook-icon {
    width: 30px;
    height: 30px;
    color: #0866ff;
    cursor: pointer;
  }
  .instagram-icon {
    width: 30px;
    height: 30px;
    color: #e4405f;
    cursor: pointer;
  }
  .eagle {
    display: flex;

    flex-direction: row;
  }
  .logo-text {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    margin-top: 25px;
    color: gray;
  }

  .below-text {
    padding-left: 50px;
  }

  .whatsapp-icon {
    width: 30px;
    height: 30px;
    color: #25d366;
    cursor: pointer;
  }

  .youtube-icon {
    width: 30px;
    height: 30px;
    color: #ff0000;
    cursor: pointer;
  }

  .linkedin-icon {
    width: 30px;
    height: 30px;
    color: #0077b5;
    cursor: pointer;
  }
  .tiktok-icon {
    width: 30px;
    height: 30px;
    color: black;
    cursor: pointer;
  }

  .about {
    display: flex;
    flex-direction: column;
  }
  .social-icons {
    display: flex;
    gap: 8px;
  }
  .contactInfo {
    display: flex;
    flex-direction: column;
    margin-top: 25px;

    font-size: 12px;
    color: gray;
  }

  .support {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .flex-col {
    display: flex;
    flex-direction: column;
  }
  .button-container {
    display: flex;
    background-color: white;
    width: 11rem; /* Equivalent to w-44 */
    height: auto; /* Equivalent to h-fit */
    justify-content: center;
    align-items: center;
    padding: 0.5rem 1rem; /* Equivalent to py-2 px-4 */
    margin-bottom: 1.25rem; /* Equivalent to mb-5 */
    border-color: #0fd3bb;
    border-width: 2px;
    border-radius: 10px; /* Equivalent to rounded-lg */
  }

  .button-container:last-child {
    padding: 0.5rem 0.75rem; /* Equivalent to py-2 px-3 */
    margin-bottom: 0; /* Remove margin-bottom for the last button */
  }

  .button-image {
    width: 1.95rem; /* Equivalent to w-5 */
    height: 1.75rem; /* Equivalent to h-7 */
  }

  .button-image:last-child {
    width: 1.75rem; /* Equivalent to w-7 */
    height: 1.75rem; /* Equivalent to h-7 */
  }

  .button-text {
    margin-left: 1.25rem; /* Equivalent to ml-5 */
    margin-top: 0px;
    text-align: left;
    font-size: 14px;
    line-height: 1.25; /* Equivalent to leading-tight */
  }
  .borderTop {
    border-top: 1px solid ${({ colors }) => colors.color.lightGrey} !important;
    margin-top: 16px;
  }

  .copyright {
    margin-top: 16px;
    margin-bottom: 16px;
    font-size: 16px;
  }

  .button-text:last-child {
    margin-left: 1rem; /* Equivalent to ml-4 */
  }

  .button-text span {
    font-weight: bold;
    font-size: 16px;
  }

  h4 {
    font-size: 19.2px;
    margin-top: 15px;
    font-family: "MS_SemiBold";
  }
  a {
    display: block;
    font-size: 13.5px;
    margin-top: 7px;
    color: ${({ colors }) => colors.color.primary};
    cursor: pointer;
    paddding-bottom: 0px;
    width: fit-content;
  }

  .newsletter {
    margin-top: 20px;
  }
  .newsletter-form-parent {
    border: 1.5px solid ${({ colors }) => colors.color.lightGrey};
    border-radius: 10px;
    max-width: 290px;
    margin-top: 5px;
    padding: 5px 0;
  }
  .icons {
    border-top: 3px solid ${({ colors }) => colors.color.lightGrey} !important;
  }
  .icon {
    width: 190px;
  }
  .apple-icon {
    width: 180px;

    height: 50px;
  }
  .play-icon {
    width: 180px;

    height: 70px;
  }
  form {
    display: flex;
  }
  p {
    text-align: center;
    margin: 30px 0 10px 0;
    font-size: 10px;
  }

  @media only screen and (min-width: 480px) {
    .eagle {
      display: flex;
    }
    .logo-icon {
      width: 180px;
    }
    .falcon-icon {
      width: 50px;
    }
    .footer-section {
      flex-direction: row;
      margin-top: 10px;
      margin-bottom: 10px;
      display: flex;

      justify-content: space-evenly;
      align-items: center;
      padding-left: 20px;
      padding-right: 20px;
    }
    h4 {
      margin-bottom: 8px;
    }
    a {
      margin-top: 10px;
    }
    .newsletter {
      margin-top: 0;
    }
  }

  @media only screen and (max-width: 480px) {
    .below-text {
      padding-left: 10px;
    }
    .contactInfo {
      justify-content: center;
      align-items: center;
    }
    .eagle {
      display: flex;
    }
    .footer-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      a {
        text-align: center;
      }
      h4 {
        text-align: center;
      }

      justify-content: center;
    }

    .support {
      text-align: center;
      display: flex;
      justify-center: center;
      flex-direction: column;
      align-items: center;
    }
    .community {
      text-align: center;
      display: flex;
      justify-center: center;
      flex-direction: column;
      align-items: center;
    }
  }
  @media only screen and (max-width: 980px) {
    .footer-section {
      flex-direction: column;
    }
    .logo-icon {
      width: 150px;
      margin: auto;
    }
    .falcon-icon {
      width: 50px;
    }
    .icon {
      width: 150px;
    }
    .apple-icon {
      width: 140px;
      padding-left: 12px;
      height: 40px;
    }
  }
`;
