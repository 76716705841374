import React, { useEffect, useState } from "react";
import InputField from "./InputField";
import { useSearchParams } from "react-router-dom";
import Button from "./Button";

const SearchFilter = ({ paramId }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState(searchParams.get("") || "");

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    const searchText = e.target.searchText.value;
    setSearchParams((prev) => {
      prev.set(paramId, searchText);
      return prev;
    });
  };

  useEffect(() => {
    setValue(searchParams.get(paramId) || "");
  }, [searchParams, paramId]);

  return (
    <form
      className="d-flex align-content-center w-100"
      onSubmit={handleSearchSubmit}
    >
      <InputField
        className="home-gig-search"
        placeholder="e.g home maintenance"
        name="searchText"
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />
      <Button type="submit" children="Search" className="search-btn" />
    </form>
  );
};

export default SearchFilter;
