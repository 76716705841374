import { useState, useEffect } from "react";

import ModalPayment from "../../payment/ModalPayment";
import JobOffer from "./JobOffer";
import { FiChevronDown } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import {
  getCardsList,
  getJobOffers,
  rejectJobOffer,
} from "../../../store/thunks";

/**
 * @typedef {Object} Props
 * @property {string} jobId
 */
const JobOffersSection = (/** @type {Props} **/ { jobId }) => {
  const dispatch = useDispatch();
  const [showOffers, setShowOffers] = useState(true);
  const { jobOffers, loading } = useSelector((state) => state.jobs);
  const [PaymentModalState, setPaymentModal] = useState(null);

  const handleRejectJobOffer = (offer) => {
    dispatch(rejectJobOffer(offer));
  };

  const handleAcceptJobOffer = async (offer) => {
    setPaymentModal({ ...offer, type: "job" });
  };

  useEffect(() => {
    dispatch(getJobOffers({ _id: jobId }));
    dispatch(getCardsList());
  }, [dispatch, jobId]);

  return (
    <>
      <div id="offers">
        <div
          className="offers-section"
          onClick={() => setShowOffers(!showOffers)}
        >
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="mb-0">Offers</h5>
            <FiChevronDown />
          </div>
        </div>
        {showOffers && (
          <div className="offers-list" style={{ minHeight: "200px" }}>
            {jobOffers.length > 0 ? (
              jobOffers?.map((offer) => {
                return (
                  <JobOffer
                    key={offer._id}
                    data={offer}
                    onAccept={handleAcceptJobOffer}
                    onReject={handleRejectJobOffer}
                  />
                );
              })
            ) : jobOffers.length === 0 && !loading ? (
              <div className="d-flex align-items-center justify-content-center w-100 h-100">
                <p className="grey-clr text-center">No Offers to show</p>
              </div>
            ) : (
              loading && (
                <div className="d-flex align-items-center justify-content-center w-100 h-100">
                  <p className="grey-clr text-center">Loading...</p>
                </div>
              )
            )}
          </div>
        )}
      </div>
      <ModalPayment
        offer={PaymentModalState}
        isOpen={Boolean(PaymentModalState)}
        onClose={() => {
          setPaymentModal(null);
        }}
      />
    </>
  );
};

export default JobOffersSection;
