import React, { useEffect } from "react";
import { colors } from "../../utils/Colors";
import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useAuthContext } from "../../features/authentication";
import { JobDetailStyles } from "../../components/Jobs/JobDetailStyled";
import { BsChevronRight } from "react-icons/bs";
import { getJobById } from "../../store/thunks";
import JobInfoSection, {
  JobInfoPlaceholder,
} from "../../components/Jobs/JobInfoSection";
import CreateOfferForm from "../../components/Jobs/CreateOfferForm";
import RectanglePlaceholder from "../../components/common/Placeholders/RectanglePlaceholder";
import JobOffersSection from "../../components/Jobs/JobOffersSection";

/** @typedef {import('../../types/server').JobDetailData} JobDetailDataState */
const JobDetail = () => {
  const { state: initalData } = useLocation();
  const queryParams = useParams();
  const dispatch = useDispatch();
  const jobId = queryParams.jobId;
  const { data: jobData, loading: jobLoading } = useSelector(
    (state) => state.jobs.getJobById
  );

  const data = /** @type {import('../../types/server').JobDetailData} **/ (
    jobData || initalData
  );

  const { user } = useAuthContext();

  // Not going the use the data from the state because it is not updated, and sometime createBy._id is undefined
  const initalLoading = jobLoading;

  const isCurrentUserTheCreator =
    user && data && user._id === data.createdBy._id;

  useEffect(() => {
    dispatch(getJobById({ id: jobId }));
  }, [dispatch, jobId]);

  return (
    <>
      <Header />
      <JobDetailStyles colors={colors} className="container">
        <div className="d-flex flex-column gap-2 mb-3">
          <div className="d-flex gap-2 align-items-center text-muted text-sm">
            {initalLoading ? (
              <>
                <p style={{ width: 80 }}>
                  <span className="placeholder placeholder-grey w-100"></span>
                </p>
                <RectanglePlaceholder size={12} className="mb-2" />
                <p style={{ width: 80 }}>
                  <span className="placeholder placeholder-grey w-100"></span>
                </p>
              </>
            ) : (
              <>
                <p className="text-capitalize">{data.category.name}</p>
                <BsChevronRight size={8} />
                <p className="text-capitalize">{data.subcategory.name}</p>
              </>
            )}
          </div>
          {initalLoading ? (
            <h2 className="placeholder-glow w-25" style={{ height: 24 }}>
              <span className="placeholder placeholder-grey w-100"></span>
            </h2>
          ) : (
            <h3 className="m-0">
              Job <span className="falcon-clr">Details</span>
            </h3>
          )}
        </div>
        {initalLoading ? <JobInfoPlaceholder /> : <JobInfoSection {...data} />}
        {initalLoading ? null : !user ? null : isCurrentUserTheCreator ? (
          <JobOffersSection jobId={data._id} />
        ) : (
          <CreateOfferForm
            jobId={data?._id}
            offer={{ budgetMax: data?.budgetMax, budgetMin: data?.budgetMin }}
          />
        )}
      </JobDetailStyles>
      <Footer />
    </>
  );
};

export default JobDetail;
